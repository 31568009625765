<template>
    <div class="list-group position-relative">
        <uploads-list-item
            v-for="file in uploads"
            :key="file._id"
            :file="file"
            :isRemoved="removed.includes(file._id)"
            @remove="onRemove"
            @restore="onRestore"
        />
    </div>
</template>

<script>
import UploadsListItem from './UploadsListItem.vue'

export default {
    name: 'UploadsList',
    components: {
        UploadsListItem
    },
    props: {
        uploads: {
            type: Array,
            required: true
        },
        removed: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        onRemove (file) {
            this.$emit('remove', file)
        },
        onRestore (file) {
            this.$emit('restore', file)
        }
    }
}
</script>
