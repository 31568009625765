<template>
    <div
        class="list-group-item d-flex align-items-center align-content-center mw-100"
    >
        <div class="d-flex w-100 mw-100 align-items-center align-content-center">
            <div class="mr-3 text-muted">
                <b-icon :icon="getIcon(file.mimeType)" font-scale="2" />
            </div>
            <div class="w-100">
                <p class="mb-0">
                    <span :class="{'d-inline-block text-truncate': true, 'text-decoration-line-trough': isRemoved }">
                        {{file.filename}}
                    </span>
                </p>
                <p v-if="!confirm" class="mb-0 text-muted"><small>{{((isRemoved) ? $t('uploadsList.toBeRemoved') : renderSize(file.size))}}</small></p>
                <div v-else class="alert alert-primary py-1 px-2 text-center mb-0">
                    <p class="mb-1">{{$t('uploadsList.deleteConfirm')}}</p>
                    <b-btn
                        variant="primary"
                        size="sm"
                        @click.prevent="onRemove(file)"
                        class="mr-3"
                    >
                        {{$t('uploadsList.remove')}}
                    </b-btn>

                    <b-btn
                        variant="danger"
                        size="sm"
                        @click.prevent="confirm = false"
                    >
                        {{$t('uploadsList.cancel')}}
                    </b-btn>
                </div>
            </div>
        </div>
        <div v-if="!confirm">
            <b-btn
                size="sm"
                variant="danger"
                @click.prevent="confirm = true"
                v-if="!isRemoved"
            >
                <b-icon icon="trash" />
            </b-btn>

            <b-btn
                size="sm"
                variant="primary"
                @click.prevent="onRestore"
                v-else
            >
                <b-icon icon="arrow-clockwise" />
            </b-btn>
        </div>
    </div>
</template>

<script>
function fileSizeSI (a, b, c, d, e) {
    // eslint-disable-next-line no-return-assign
    return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2) +
 ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
}

export default {
    name: 'UploadsListItem',
    props: {
        file: {
            type: Object,
            required: true
        },
        isRemoved: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    data () {
        return {
            confirm: false
        }
    },
    methods: {
        getIcon (mimeType) {
            switch (mimeType) {
                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.oasis.opendocument.text':
                    return 'file-spreadsheet'
                case 'application/vnd.oasis.opendocument.spreadsheet':
                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'file-text'
                default:
                    return 'file-post'
            }
        },
        renderSize (fileSize) {
            return fileSizeSI(fileSize)
        },
        onRemove () {
            this.confirm = false
            this.$emit('remove', this.file)
        },
        onRestore () {
            this.$emit('restore', this.file)
        }
    }
}
</script>

<style>
    .mw-100 {
        max-width: 100%;
    }

    .text-truncate {
        text-overflow: ellipsis;
    }

    .text-decoration-line-trough {
        text-decoration: line-through;
    }
</style>
